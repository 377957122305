var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel"},[_c('v-carousel',{attrs:{"value":_vm.slide,"height":"","continuous":false,"interval":_vm.interval,"hide-delimiters":"","show-arrows-on-hover":!_vm.isMobile,"show-arrows":""},on:{"change":function($event){return _vm.$emit('update:slide', $event)}}},_vm._l((_vm.items),function(item){return _c('v-carousel-item',{key:item.id,attrs:{"eager":_vm.loadAll}},[_c('site-carousel-item',{staticClass:"rounded-lg",attrs:{"item":item,"easy":_vm.easy,"easy-contain":_vm.easyContain,"tile":_vm.tile,"listing-id":_vm.listingId,"item-height":_vm.itemHeight,"eager":""}})],1)}),1),(_vm.showDots)?_c('div',{class:[
      'carousel-dots',
      { 'carousel-dots-easy': _vm.easy },
      'd-flex',
      'justify-center',
    ]},_vm._l((_vm.getVisibleDots),function(d){return _c('button',{key:`dot-${d.index}`,class:[
        {
          active: d.active,
          'more-indicator': d.isMoreIndicator,
        },
        'carousel-dots-item',
      ],on:{"click":function($event){d.isMoreIndicator ? null : _vm.$emit('update:slide', d.index)}}})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }