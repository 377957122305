<template>
  <div class="carousel">
    <v-carousel
      :value="slide"
      height
      :continuous="false"
      :interval="interval"
      hide-delimiters
      :show-arrows-on-hover="!isMobile"
      show-arrows
      @change="$emit('update:slide', $event)"
    >
      <v-carousel-item v-for="item in items" :key="item.id" :eager="loadAll">
        <site-carousel-item
          class="rounded-lg"
          :item="item"
          :easy="easy"
          :easy-contain="easyContain"
          :tile="tile"
          :listing-id="listingId"
          :item-height="itemHeight"
          eager
        />
      </v-carousel-item>
    </v-carousel>
    <div
      v-if="showDots"
      :class="[
        'carousel-dots',
        { 'carousel-dots-easy': easy },
        'd-flex',
        'justify-center',
      ]"
    >
      <button
        v-for="d in getVisibleDots"
        :key="`dot-${d.index}`"
        :class="[
          {
            active: d.active,
            'more-indicator': d.isMoreIndicator,
          },
          'carousel-dots-item',
        ]"
        @click="d.isMoreIndicator ? null : $emit('update:slide', d.index)"
      />
    </div>
  </div>
</template>

<script>
import SiteCarouselItem from "@/components/common/carousel/site-carousel-item.vue";
import DeviceMixin from "@/mixins/device-mixin";
export default {
  name: "site-carousel",
  mixins: [DeviceMixin],
  components: { SiteCarouselItem },
  props: {
    easy: {
      type: Boolean,
      default: false,
    },
    easyContain: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    slide: {
      type: Number,
      default: 0,
    },
    showDots: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 6000,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    listingId: {
      type: [Number, String],
      default: null,
    },
    itemHeight: {
      type: String,
      default: null,
    },
    loadAll: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loadNextImage(this.slide);
  },
  watch: {
    slide(val) {
      this.loadNextImage(val);
    },
  },
  methods: {
    loadNextImage(val) {
      const nextIndex = (val + 1) % this.items.length;
      const nextImage = new Image();
      nextImage.src = this.items[nextIndex];
    },
    shouldShowDot(index) {
      if (this.items.length <= 5) return true;
      if (index < 5) return true;
      if (index === this.slide) return true;

      return false;
    },
  },
  computed: {
    getVisibleDots() {
      if (this.items.length <= 5) {
        return this.items.map((_, index) => ({
          index,
          active: this.slide === index,
          isMoreIndicator: false,
        }));
      }
      let dots = [];
      for (let i = 0; i < 4; i++) {
        dots.push({
          index: i,
          active: this.slide === i,
          isMoreIndicator: false,
        });
      }
      if (this.slide >= 4) {
        dots.push({
          index: this.slide,
          active: true,
          isMoreIndicator: false,
        });
      }
      dots.push({
        index: this.items.length - 1,
        active: false,
        isMoreIndicator: true,
      });

      return dots;
    },
  },
};
</script>

<style lang="scss">
.carousel {
  position: relative;
  &-dots {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    &-easy {
      position: absolute;
      padding-top: 15px;
      padding-bottom: 25px;
      bottom: 0;
      .carousel-dots-item {
        background-color: #fff;
      }
    }
    &-item {
      display: block;
      background-color: #000;
      margin-left: 4px;
      margin-right: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      opacity: 0.3;
      &.active {
        opacity: 1;
      }
      &.more-indicator {
        width: 12px;
        border-radius: 3px;
      }
    }
  }
}
</style>
