<template>
  <v-card
    @click="listingClick"
    class="listings-item"
    :class="{ 'mb-3': !horizontal }"
    elevation="0"
    :outlined="horizontal"
    rounded="lg"
  >
    <div class="p-relative">
      <v-toolbar
        class="pt-3"
        color="transparent"
        width="100%"
        absolute
        dense
        flat
      >
        <v-spacer />
      </v-toolbar>
    </div>
    <v-row :class="!horizontal ? 'flex-column' : ''">
      <v-col cols="12" :lg="carouselLg">
        <site-carousel
          :items="thumbnails[0] ? thumbnails : [PLACEHOLDER]"
          :listing-id="item.id"
          :slide.sync="slideIndex"
          easy
          :item-height="horizontal ? '200px' : '250px'"
          :class="{ 'listing-placeholder': !thumbnails[0] }"
        />
      </v-col>
      <v-col :class="{ 'pt-0': !horizontal, 'my-2 me-2': horizontal }">
        <div class="d-flex flex-column fill-height">
          <v-card-title
            style="font-size: 1rem; font-weight: 600; line-height: 1.3rem"
            :class="['mb-2 pa-0 flex-column align-start']"
          >
            <div class="d-flex">
              <span class="ellipsis-1">{{ item.title }}</span>
              <span
                v-if="item.airbnb_score"
                class="text-caption pre d-flex align-center"
              >
                <v-icon size="16" color="yellow darken-2">mdi-star</v-icon>
                {{ item.airbnb_score }}
              </span>
            </div>
            <div class="font-weight-regular text-body-2 my-1">
              {{ location }}
            </div>
            <div
              :class="[
                'text-caption text--darken-2 w-full d-inline-flex align-center',
                { 'justify-space-between': !horizontal },
              ]"
            >
              <div class="d-flex text-body-2 mt-0">
                <span>{{ listingInfo.guests.count }} Guests</span>
                <span class="mx-2">|</span>
                <span>{{ listingInfo.beds.count }} Bedrooms</span>
                <span class="mx-2">|</span>
                <span>{{ listingInfo.bathrooms.count }} Bathrooms</span>
              </div>
            </div>
            <div
              v-if="horizontal && item.marketing_content?.summary"
              class="text-caption text--secondary mt-3 listing-description"
            >
              {{ item.marketing_content.summary }}
            </div>
          </v-card-title>
          <div class="mt-auto">
            <div
              v-if="item.extra_info.current_price"
              class="d-flex align-center"
            >
              <span
                class="font-weight-semibold"
                v-text="
                  toMoney(
                    item.extra_info.current_price.our_price,
                    0,
                    currencySign
                  )
                "
              />
              <span
                class="ml-1"
                v-if="
                  item.extra_info.current_price.total_price !==
                  item.extra_info.current_price.our_price
                "
                >+ Fees</span
              >
              <v-icon class="mx-3" size="3">fas fa-circle</v-icon>
              <span
                class="nights-amount text-caption text--secondary font-weight-medium"
                >{{ days_count }} nights</span
              >
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { AMENITY_ICONS } from "@/consts/AMENITIES";
import { mapActions } from "vuex";
import MediaMixin from "@/mixins/media-mixin";
import FormattersMixin from "@/mixins/formatters-mixin";
import SiteCarousel from "@/components/common/carousel/site-carousel.vue";
// import ListingAmenities from "@/components/listing/listing-amenities";
import stylesMixin from "@/mixins/styles-mixin";
import PLACEHOLDER from "@/assets/images/listing-placeholder.webp";

export default {
  name: "site-listings-item",
  mixins: [MediaMixin, FormattersMixin, stylesMixin],
  components: { SiteCarousel },
  props: {
    item: {
      type: Object,
    },
    index: {
      type: Number,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    showCalculations: {
      type: Boolean,
      default: false,
    },
    carouselLg: {
      type: Number,
      default: 12,
    },
  },
  data: () => ({ PLACEHOLDER, AMENITY_ICONS, slideIndex: 0 }),
  methods: {
    ...mapActions("listingsModule", ["updateListing"]),
    toggleFavorite(data) {
      const req = Object.assign({}, data);
      req.item.isFavorited = !req.item.isFavorited;
      this.updateListing(req);
    },
    listingClick() {
      this.$router.push({
        name: "listing",
        params: { id: this.item.id },
        query: this.$route.query,
      });
    },
  },

  computed: {
    thumbnails() {
      return this.item.pictures.length
        ? this.item.pictures?.map((p) =>
            (p.thumbnail || p.large || p.regular || p.original)
              .replace("upload/", "upload/c_fit,h_400/")
              .replace("h_200", "h_400")
          )
        : [this.item.picture];
    },
    listingInfo() {
      const beds = { type: "bedrooms", count: this.item.beds };
      const bathrooms = { type: "bathrooms", count: this.item.baths };
      const guests = { type: "guests", count: this.item.accommodates };
      return { guests, beds, bathrooms };
    },
    days_count() {
      const { check_in, check_out } = this.$route.query;
      if (!(check_in && check_out)) return null;
      return this.$moment(check_out).diff(this.$moment(check_in), "days");
    },
    location() {
      const { city_name, state_name } = this.item;
      if (!city_name && !state_name) return null;
      return `${city_name} ${state_name ? `, ${state_name}` : ""}`;
    },
    listing() {
      // for currency mixin
      return this.item;
    },
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
.ellipsis-1 {
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}
.pre {
  white-space: pre;
}
.listing-placeholder {
  opacity: 0.1;
}
.listing-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-height: 1.6;
  word-break: keep-all;
  color: rgba(0, 0, 0, 0.6);
}
</style>
